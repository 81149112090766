import React, { useContext, useEffect, useState } from 'react'
import collapse from '../../Assets/Images/collapse.png'
import expand from '../../Assets/Images/expand.png';
import CompletionBar from '../CompletionBar';
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import leader from "../../Assets/Images/leader.svg";
import parishioner from "../../Assets/Images/parishioner.svg";
import DesignationContext from '../../Store/DesignationContext';
import { Tooltip } from '@mui/material';
import LanguageContext from '../../Store/LanguageContext';
import { translate } from '../translate/translate';

const Matrix = ({ title = "", questions, color = "green", open = 'collapse', setOpen, image = "", from, pageData, section, currentTab }) => {

  // const [show, setShow] = useState(open);
  const { windowWidth } = useWindowDimensions();
  const designationCtx = useContext(DesignationContext);
  const langCtx = useContext(LanguageContext);
  const negativeQuestions = [1126, 1102, 1106, 1112]

  // console.log("questions: ", questions)
  // console.log("section: ", section)

  const handleTranslate = (text, type) => {

    switch (langCtx.language) {
      case "Nederlands": {
        return translate(text, "dashboardAnswersDutch", type)
      }
      default: {
        return text;
      }
    }

  }

  // console.log("translation test: ",handleTranslate("Strongly Agree","strongly-agree"))


  const getInsightsAnswerDiv = (userTypeScore, id) => {
    const negativeQuestions = [1126, 1102, 1106, 1112]
    const roundedValue = Math.round(userTypeScore);

    let cellStyle = { fontWeight: "700", width: '17%' };

    if (userTypeScore >= 0 && userTypeScore < 20) {
      if (negativeQuestions?.includes(id)) {
        cellStyle.color = "#84161D";
        return <div className='b-cell' style={cellStyle}>{handleTranslate("Strongly Agree", "strongly-agree")}</div>;
      }
      else {
        cellStyle.color = "#84161D";
        return <div className='b-cell' style={cellStyle}>{handleTranslate("Strongly Disagree", "strongly-disagree")}</div>;
      }
    }
    else if (userTypeScore >= 20 && userTypeScore < 40) {
      if (negativeQuestions?.includes(id)) {
        cellStyle.color = "#D55D53";
        return <div className='b-cell' style={cellStyle}>{handleTranslate("Agree", "agree")}</div>;
      }
      else {
        cellStyle.color = "#D55D53";
        return <div className='b-cell' style={cellStyle}>{handleTranslate("Disagree", "disagree")}</div>;
      }
    }
    else if (userTypeScore >= 40 && userTypeScore < 60) {
      if (negativeQuestions?.includes(id)) {
        cellStyle.color = "#D55D53";
        return <div className='b-cell' style={cellStyle}>{handleTranslate("Agree", "agree")}</div>;
      }
      else {
        cellStyle.color = "#EBBD79";
        return <div className='b-cell' style={cellStyle}>{handleTranslate("Neutral", "neutral")}</div>;
      }
    }
    else if (userTypeScore >= 60 && userTypeScore < 85) {
      if (roundedValue == 50 && section === 'areasOfGrowth') {
        cellStyle.color = "#D55D53";
        return <div className='b-cell' style={cellStyle}>{handleTranslate("Disagree", "disagree")}</div>;
      }

      if (negativeQuestions?.includes(id)) {
        cellStyle.color = "#9BB675";
        return <div className='b-cell' style={cellStyle}>{handleTranslate("Disagree", "disagree")}</div>;
      }
      else {
        cellStyle.color = "#9BB675";
        return <div className='b-cell' style={cellStyle}>{handleTranslate("Agree", "agree")}</div>;
      }
    }
    else if (userTypeScore >= 85 && userTypeScore <= 100) {
      if (negativeQuestions?.includes(id)) {
        cellStyle.color = "#87976E";
        return <div className='b-cell' style={cellStyle}>{handleTranslate("Strongly Disagree", "strongly-disagree")}</div>;
      }
      else {
        cellStyle.color = "#87976E";
        return <div className='b-cell' style={cellStyle}>{handleTranslate("Strongly Agree", "strongly-agree")}</div>;
      }
    }
    else {
      cellStyle.color = "#9E9E9E";
      return <div className='b-cell' style={cellStyle}>N/A</div>;
    }


  }

  // const getCompareAnswerDiv = (userTypeScore) => {
  //   const roundedValue = Math.round(userTypeScore);

  //   switch (roundedValue) {
  //     case 1:
  //       return <div className='b-cell' style={{ color: "#84161D", fontWeight: "700", width: '16%' }}>Strongly Disagree</div>;
  //       break;
  //     case 2:
  //       return <div className='b-cell' style={{ color: "#D55D53", fontWeight: "700", width: '16%' }}>Disagree</div>;
  //       break;
  //     case 3:
  //       return <div className='b-cell' style={{ color: "#EBBD79", fontWeight: "700", width: '16%' }}>Neutral</div>;
  //       break;
  //     case 4:
  //       return <div className='b-cell' style={{ color: "#87976E", fontWeight: "700", width: '16%' }}>Agree</div>;
  //       break;
  //     case 5:
  //       return <div className='b-cell' style={{ color: "#9E9E9E", fontWeight: "700", width: '16%' }}>Strongly Agree</div>;
  //       break;
  //     default:
  //       return <div className='b-cell' style={{ color: "#C65B5B", fontWeight: "700", width: '16%' }}>N/A</div>;
  //       break;
  //   }
  // }

  const getCompareAnswerDiv = (userTypeScore, id) => {
    const negativeQuestions = [1126, 1102, 1106, 1112];
    const roundedValue = Math.round((userTypeScore / 25) + 1);

    // console.log("id: ",id)
    // console.log("id: ",negativeQuestions.includes(id))

    let cellStyle = { fontWeight: "700", width: '17%' };

    if (roundedValue == 1) {
      cellStyle.color = "#84161D";
      return (
        <div className='b-cell' style={cellStyle}>
          {negativeQuestions.includes(id) ? `${handleTranslate("Strongly Agree", "strongly-agree")}` : `${handleTranslate("Strongly Disagree", "strongly-disagree")}`}
        </div>
      );
    }
    else if (roundedValue == 2) {
      cellStyle.color = "#D55D53";
      return (
        <div className='b-cell' style={cellStyle}>
          {negativeQuestions.includes(id) ? `${handleTranslate("Agree", "agree")}` : `${handleTranslate("Disagree", "disagree")}`}
        </div>
      );
    }
    else if (roundedValue == 3) {
      cellStyle.color = "#EBBD79";
      return (
        <div className='b-cell' style={cellStyle}>
          {negativeQuestions.includes(id) ? `${handleTranslate("Neutral", "neutral")}` : `${handleTranslate("Neutral", "neutral")}`}
        </div>
      );
    }
    else if (roundedValue == 4) {
      cellStyle.color = "#87976E";
      return (
        <div className='b-cell' style={cellStyle}>
          {negativeQuestions.includes(id) ? `${handleTranslate("Disagree", "disagree")}` : `${handleTranslate("Agree", "agree")}`}
        </div>
      );
    }
    else if (roundedValue == 5) {
      cellStyle.color = "#9E9E9E";
      return (
        <div className='b-cell' style={cellStyle}>
          {negativeQuestions.includes(id) ? `${handleTranslate("Strongly Disagree", "strongly-disagree")}` : `${handleTranslate("Strongly Agree", "strongly-agree")}`}
        </div>
      );
    }
    else {
      cellStyle.color = "#C65B5B";
      return (
        <div className='b-cell' style={cellStyle}>
          N/A
        </div>
      );
    }
  };


  const getCompletionScoreLeader = (questions) => {
    let answer = 0;
    questions?.forEach(element => {
      answer += (element?.averages?.leaders) * 20
    });
    answer /= questions?.length
    return answer
  }

  const getCompletionScoreParishioners = (questions) => {
    let answer = 0;
    questions?.forEach(element => {
      answer += (element?.averages?.parishioners) * 20
    });
    answer /= questions?.length
    return answer
  }

  const getAnswersAccordingToTab = (item) => {
    switch (from) {
      case "insights":
        return <>
          {getInsightsAnswerDiv(item?.leaderAverage, item?.questionId)}
          {getInsightsAnswerDiv(item?.parishionerAverage, item?.questionId)}
        </>
        break;
      case "compare":
        return <>
          {getCompareAnswerDiv((item?.averages?.leaders - 1) * 25, item?.questionId)}
          {getCompareAnswerDiv((item?.averages?.parishioners - 1) * 25, item?.questionId)}
        </>
        break;
    }
  }

  const getInsightsTooltip = (title) => {
    switch (title) {
      case "Experience of the Holy Spirit":
        return "The parish and the lives of people in the parish have been transformed by the experience of the Holy Spirit."
        break;
      case "Asking & Discerning in Faith":
        return "There is an openness and sensitivity to hearing what the Holy Spirit would say to the parish beyond human wisdom. There is intentional time given to reflect and listen to the direction of the Spirit in vision, strategy, and practice within the parish."
        break;
      case "Responding in Faith":
        return "Actions of risk, dependence, and full obedience to the Holy Spirit's direction are repeatedly demonstrated."
        break;
      case "Resources Aligned":
        return "The resources of the parish are allocated disproportionally toward helping people inside and outside the parish hear the good news (like Alpha) for themselves and choose to follow Jesus."
        break;
      case "Primacy of Programs":
        return "The programs for evangelization are given priority in planning and execution within the parish."
        break;
      case "Cultural Reinforcement":
        return "The disposition of the parish and the primary focus of the parish encourage everyone and everything in the parish to promote evangelization."
        break;
      case "Vision":
        return "Discerning, articulating, and casting vision in such a manner that it is clear where God is calling the parish."
        break;
      case "Planning and Priorities":
        return "Putting clear action steps together so that the priorities, systems, and next steps to fulfilling the vision become a reality."
        break;
      case "Leading out of a team":
        return "Sharing leadership through mobilizing others to lead out of their gifts and play their part in bringing the vision to life."
        break;
      case "Growing Leadership Skills":
        return "The ongoing development of one's leadership gifts as well as the leadership gifts of others."
        break;
      case "Ervaring van de Heilige Geest":
        return "De parochie en het leven van de mensen in de parochie is veranderd door de ervaring van de Heilige Geest.";
      case "Vragen en onderscheiden in geloof":
        return "Er is een openheid en gevoeligheid om te horen wat de Heilige Geest de parochie wil zeggen. Er wordt opzettelijk tijd vrijgemaakt om te luisteren naar de richting van de Geest voor de visie, strategie en praktijken binnen de parochie."
      case "Vol geloof reageren":
        return "De parochie laat zien dat zij afhankelijk van en gehoorzaam aan de Heilige Geest wil zijn door het nemen van risico’s."
      case "Besteding middelen":
        return "Een groot deel van de middelen van de parochie worden gebruikt om mensen binnen en buiten de parochie te helpen het goede nieuws te horen en ervoor te kiezen Jezus te volgen (bijvoorbeeld door Alpha).";
      case "Voorrang voor trajecten":
        return "De trajecten voor evangelisatie krijgen prioriteit bij de planning en uitvoering binnen de parochie"
      case "Cultuurversterking":
        return "De cultuur en focus binnen de parochie moedigen iedereen en alles aan om evangelisatie te bevorderen"
      case "visie":
        return "Het onderscheiden, verwoorden en uitdragen van een visie op zo'n manier dat het duidelijk is waar God de parochie naartoe roept."
      case "Planning en prioriteiten":
        return "Duidelijke plannen samenstellen zodat de visie werkelijkheid wordt."
      case "Leiden vanuit een team":
        return "Leiderschap delen door anderen uit te nodigen om leiding te nemen vanuit hun gaven en een rol te spelen in het tot leven brengen van de visie."
      case "Groeiende leiderschapsvaardigheden":
        return "De voortdurende ontwikkeling van de eigen en andermans leiderschapskwaliteiten."
    }
  }

  const getTranslatedQuestion = (item) => {
    // console.log('item: ',item)
    const selectedLanguage = item?.languages?.find(el => el?.language === langCtx?.language || "Dutch")

    if (langCtx?.language !== "English")
      return selectedLanguage?.answers[0]?.question
    else
      return item?.question
  }

  return (
    <div className={`matrix ${color} ${open}`}>
      {
        (questions?.length !== 0 && questions) && <>
          <div className='head'>
            <div className={`row ${from}`} >
              <div className='h-cell' style={{ width: windowWidth >= 1240 ? '63%' : '100%' }} >
                {from === 'insights' && <img className='thead-img' src={image} />} 
                <p style={{ margin: "0" }}>{title}</p> 
                {from === 'insights' && <Tooltip title={getInsightsTooltip(title)}>
                  <span>
                    &#8202; &#8202;
                    <span className="superscript" style={{ cursor: "pointer" }}>&#9432;</span>
                  </span>
                </Tooltip>}
              </div>
              {windowWidth >= 1240 && <div className='h-cell' style={{ width: '17%' }}>{from === 'compare' && <CompletionBar border={true} complete={getCompletionScoreLeader(questions)} from={from} />}</div>}
              {windowWidth >= 1240 && <div className='h-cell' style={{ width: '17%' }}>{from === 'compare' && <CompletionBar border={true} complete={getCompletionScoreParishioners(questions)} from={from} />} {color === 'blue' && windowWidth >= 1240 && <p className='alignment-text'><strong>({questions?.length})</strong> {langCtx.language ==="Nederlands"? "gebieden die afstemming nodig hebben":"areas that need alignment"}</p>}</div>}
              <div className='h-cell collapse' style={{ width: '3%' }}><img className='collapse-img' src={open === "collapse" ? collapse : expand} onClick={() => setOpen(open === "collapse" ? false : true)} /></div>
            </div>
          </div>

          <div className='body' style={open === "collapse" ? { display: "block" } : { display: "none" }}>

            {windowWidth <= 831 ?
              <>
                <div className='row mobile-sub-head' style={{ minHeight: "100px" }} >
                  <div className='row'>
                    <div className='h-cell lepa-container'  >
                      <div className={`lp-container ${from}`}>
                        <div className='contents' >
                          <img src={leader} alt="" />
                          <p style={{ whiteSpace: 'nowrap' }}>{pageData?.leaderCount} Leaders</p>
                        </div>
                        {from === 'compare' && <CompletionBar border={true} complete={getCompletionScoreParishioners(questions)} />}
                      </div>
                    </div>
                    <hr />
                    <div className='h-cell lepa-container'  >
                      <div className={`lp-container ${from}`}>
                        <div className='contents' >
                          <img src={parishioner} alt="" />
                          <p style={{ whiteSpace: 'nowrap' }}>{pageData?.parishionerCount} Parishioners</p>
                        </div>
                        {from === 'compare' && <CompletionBar border={true} complete={getCompletionScoreLeader(questions)} />}
                      </div>
                    </div>
                  </div>

                  <div style={{ display: 'flex' }}>
                    <div className='h-cell' style={{ width: "47%" }}>

                    </div>
                    <div className='h-cell ' style={{ width: '16%' }}>
                      Leaders
                    </div>
                    <div className='h-cell' style={{ width: '16%' }}>
                      Parishioners
                    </div>
                  </div>

                </div>
              </>
              : null
            }
            {questions?.map((item, index) => {
              // { console.log('item: ',item) }
              return <div className='row' key={index}>
                <div className='b-cell' style={{ width: '63%' }}>{getTranslatedQuestion(item)}</div>
                {getAnswersAccordingToTab(item)}
                {windowWidth >= 831 && <div className='b-cell' style={{ width: '3%' }}></div>}
              </div>
            })}
          </div>
        </>
      }
    </div>
  )
}

export default Matrix