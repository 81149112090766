import React, { useState, useEffect, useContext } from "react";
import HolySprit from "../../Assets/Images/holy-spirit.svg";
import Evangelization from "../../Assets/Images/evangelization.svg";
import leadership from "../../Assets/Images/leadership.svg";
import CompletionBar from "../CompletionBar";
import { Tooltip, IconButton } from "@mui/material";
import LanguageContext from "../../Store/LanguageContext";
import { translate } from "../translate/translate";

function Scores(props) {

  const langCtx = useContext(LanguageContext)

  const { apiData = {}, type = "parish" } = props;

  const [subScore1, setSubScore1] = useState(0);
  const [subScore2, setSubScore2] = useState(0);
  const [subScore3, setSubScore3] = useState(0);
  const [subScore4, setSubScore4] = useState(0);
  const [subScore5, setSubScore5] = useState(0);
  const [subScore6, setSubScore6] = useState(0);
  const [subScore7, setSubScore7] = useState(0);
  const [subScore8, setSubScore8] = useState(0);
  const [subScore9, setSubScore9] = useState(0);
  const [subScore10, setSubScore10] = useState(0);


  useEffect(() => {
    if (type === 'parish') {
      let sub1 = apiData?.overallAverage?.expAvg;
      if (sub1) { setSubScore1(sub1); }
      let sub2 = apiData?.overallAverage?.discernAvg;
      if (sub2) { setSubScore2(sub2); }
      let sub3 = apiData?.overallAverage?.respondAvg;
      if (sub3) { setSubScore3(sub3); }
      let sub4 = apiData?.overallAverage?.resourceAvg;
      if (sub4) { setSubScore4(sub4); }
      let sub5 = apiData?.overallAverage?.primacyAvg;
      if (sub5) { setSubScore5(sub5); }
      let sub6 = apiData?.overallAverage?.culturalAvg;
      if (sub6) { setSubScore6(sub6); }
      let sub7 = apiData?.overallAverage?.visionAvg;
      if (sub7) { setSubScore7(sub7); }
      let sub8 = apiData?.overallAverage?.planningAvg;
      if (sub8) { setSubScore8(sub8); }
      let sub9 = apiData?.overallAverage?.leadingAvg;
      if (sub9) { setSubScore9(sub9); }
      let sub10 = apiData?.overallAverage?.growingAvg;
      if (sub10) { setSubScore10(sub10); }
    } else if (type === 'leaders') {
      let sub1 = apiData?.leadersAverage?.expAvg;
      if (sub1) { setSubScore1(sub1); }
      let sub2 = apiData?.leadersAverage?.discernAvg;
      if (sub2) { setSubScore2(sub2); }
      let sub3 = apiData?.leadersAverage?.respondAvg;
      if (sub3) { setSubScore3(sub3); }
      let sub4 = apiData?.leadersAverage?.resourceAvg;
      if (sub4) { setSubScore4(sub4); }
      let sub5 = apiData?.leadersAverage?.primacyAvg;
      if (sub5) { setSubScore5(sub5); }
      let sub6 = apiData?.leadersAverage?.culturalAvg;
      if (sub6) { setSubScore6(sub6); }
      let sub7 = apiData?.leadersAverage?.visionAvg;
      if (sub7) { setSubScore7(sub7); }
      let sub8 = apiData?.leadersAverage?.planningAvg;
      if (sub8) { setSubScore8(sub8); }
      let sub9 = apiData?.leadersAverage?.leadingAvg;
      if (sub9) { setSubScore9(sub9); }
      let sub10 = apiData?.leadersAverage?.growingAvg;
      if (sub10) { setSubScore10(sub10); }
    } else if (type === 'parishioners') {
      let sub1 = apiData?.parishionersAverage?.expAvg;
      if (sub1) { setSubScore1(sub1); }
      let sub2 = apiData?.parishionersAverage?.discernAvg;
      if (sub2) { setSubScore2(sub2); }
      let sub3 = apiData?.parishionersAverage?.respondAvg;
      if (sub3) { setSubScore3(sub3); }
      let sub4 = apiData?.parishionersAverage?.resourceAvg;
      if (sub4) { setSubScore4(sub4); }
      let sub5 = apiData?.parishionersAverage?.primacyAvg;
      if (sub5) { setSubScore5(sub5); }
      let sub6 = apiData?.parishionersAverage?.culturalAvg;
      if (sub6) { setSubScore6(sub6); }
      let sub7 = apiData?.parishionersAverage?.visionAvg;
      if (sub7) { setSubScore7(sub7); }
      let sub8 = apiData?.parishionersAverage?.planningAvg;
      if (sub8) { setSubScore8(sub8); }
      let sub9 = apiData?.parishionersAverage?.leadingAvg;
      if (sub9) { setSubScore9(sub9); }
      let sub10 = apiData?.parishionersAverage?.growingAvg;
      if (sub10) { setSubScore10(sub10); }
    } else if (type === 'results') {
      if (apiData?.variables) {
        let sub1 = apiData?.variables?.find((obj) => obj.variable === "expavg");
        if (sub1) { setSubScore1(sub1.score); }
        let sub2 = apiData?.variables?.find((obj) => obj.variable === "discernavg");
        if (sub2) { setSubScore2(sub2.score); }
        let sub3 = apiData?.variables?.find((obj) => obj.variable === "respondavg");
        if (sub3) { setSubScore3(sub3.score); }
        let sub4 = apiData?.variables?.find((obj) => obj.variable === "primacyavg");
        if (sub4) { setSubScore4(sub4.score); }
        let sub5 = apiData?.variables?.find((obj) => obj.variable === "resourceavg");
        if (sub5) { setSubScore5(sub5.score); }
        let sub6 = apiData?.variables?.find((obj) => obj.variable === "culturalavg");
        if (sub6) { setSubScore6(sub6.score); }
        let sub7 = apiData?.variables?.find((obj) => obj.variable === "visionavg");
        if (sub7) { setSubScore7(sub7.score); }
        let sub8 = apiData?.variables?.find((obj) => obj.variable === "planningavg");
        if (sub8) { setSubScore8(sub8.score); }
        let sub9 = apiData?.variables?.find((obj) => obj.variable === "leadingavg");
        if (sub9) { setSubScore9(sub9.score); }
        let sub10 = apiData?.variables?.find((obj) => obj.variable === "growingavg");
        if (sub10) { setSubScore10(sub10.score); }
      }
    }

  }, [apiData, type])

  const handleTranslate = (text, type) => {

    // console.log('langCtx.language: ', langCtx.language)

    switch (langCtx.language) {
      case "Nederlands": {
        return translate(text, "scoresDutch", type)
      }
      default: {
        return text;
      }
    }

  }

  const getResultReportScore = (resultSectionType) => {
    var holyspirit = 0;
    var evangelism = 0;
    var leadership = 0;

    if (type === 'parish') {
      holyspirit = apiData?.overallAverage?.holyspiritScore;
      evangelism = apiData?.overallAverage?.evangelismScore;
      leadership = apiData?.overallAverage?.leadershipScore;
    } else if (type === 'leaders') {
      holyspirit = apiData?.leadersAverage?.holyspiritScore;
      evangelism = apiData?.leadersAverage?.evangelismScore;
      leadership = apiData?.leadersAverage?.leadershipScore;
    } else if (type === 'parishioners') {
      holyspirit = apiData?.parishionersAverage?.holyspiritScore;
      evangelism = apiData?.parishionersAverage?.evangelismScore;
      leadership = apiData?.parishionersAverage?.leadershipScore;
    } else if (type === 'results') {
      holyspirit = apiData?.variables?.find((obj) => obj.variable === "holyspirit")?.score;
      evangelism = apiData?.variables?.find((obj) => obj.variable === "evangelism")?.score;
      leadership = apiData?.variables?.find((obj) => obj.variable === "leadership")?.score;
    }
    let typeScore = 0;

    switch (resultSectionType) {
      case "holyspirit":
        typeScore = holyspirit;
        break;
      case "evangelism":
        typeScore = evangelism;
        break;
      case "leadership":
        typeScore = leadership;
        break;
    }

    if (typeScore < 66.66) {
      return <p className="low-score">{handleTranslate("Needs Work", "needs-work")}</p>
    }
    else if (typeScore >= 66.66 && typeScore <= 83.33) {
      return <p className="medium-score">{handleTranslate("Getting there", "getting-there")}</p>
    }
    else if (typeScore > 83.33) {
      return <p className="high-score">{handleTranslate("Doing Well", "doing-well")}</p>
    }
  }


  let Experience = `${handleTranslate("The parish and the lives of people in the parish have been transformed by the experience of the Holy Spirit.", "experience-definition")}`
  let Asking = `${handleTranslate("There is an openness and sensitivity to hearing what the Holy Spirit would say to the parish beyond human wisdom. There is intentional time given to reflect and listen to the direction of the Spirit in vision, strategy, and practice within the parish.", "asking-and-discerning-definition")}`
  let Responding = `${handleTranslate("Actions of risk, dependence, and full obedience to the Holy Spirit's direction are repeatedly demonstrated.", "responding-definition")}`
  let Resources = `${handleTranslate("The resources of the parish are allocated disproportionally toward helping people inside and outside the parish hear the good news (like Alpha) for themselves and choose to follow Jesus.", "resources-definition")}`
  let Primacy = `${handleTranslate("The programs for evangelization are given priority in planning and execution within the parish.")}`
  let Cultural = `${handleTranslate("The disposition of the parish and the primary focus of the parish encourage everyone and everything in the parish to promote evangelization.", "cultural-definition")}`
  let Vision = `${handleTranslate("Discerning, articulating, and casting vision in such a manner that it is clear where God is calling the parish.", "vision-definition")}`
  let Planning = `${handleTranslate("Putting clear action steps together so that the priorities, systems, and next steps to fulfilling the vision become a reality.", "planning-definition")}`
  let Leading = `${handleTranslate("Sharing leadership through mobilizing others to lead out of their gifts and play their part in bringing the vision to life.", "leading-definition")}`
  let Growing = `${handleTranslate("The ongoing development of one's leadership gifts as well as the leadership gifts of others.", "growing-definition")}`


  const customStyle = {
    width: "220px",
    Height: "50px",

  }

  return (
    <div className="score-container">
      <div className="card">
        <div className="image">
          <img src={"https://40parables-assets.s3.amazonaws.com/holy-spirit.svg"} alt="" />
          <p>{handleTranslate("HOLY SPIRIT", "holy-spirit")}</p>
          {getResultReportScore('holyspirit')}
        </div>
        <div className="completion-bars-container">
          <div>
            <p>{handleTranslate("Experience of the Spirit", "experience-of-the-spirit")} 
              <Tooltip title={Experience} placement="top" PopperProps={{ style: customStyle, }}>
                <IconButton style={{padding: 0}}><img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"10px",height:"10px", top: 0, position: "absolute", left: "1px"}}/> </IconButton>
              </Tooltip>
            </p>
            <CompletionBar complete={subScore1} />
          </div>
          <div>
            <p>{handleTranslate("Asking & Discerning in Faith", "asking-and-discerning-in-faith")} 
              <Tooltip title={Asking} placement="top" PopperProps={{ style: customStyle, }}> 
                <IconButton style={{padding: 0}}><img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"10px",height:"10px", top: 0, position: "absolute", left: "1px"}}/></IconButton>
              </Tooltip>
            </p>
            <CompletionBar complete={subScore2} />
          </div>
          <div>
            <p>{handleTranslate("Responding in Faith", "responding-in-faith")} 
              <Tooltip title={Responding} placement="top" PopperProps={{ style: customStyle, }}> 
                <IconButton style={{padding: 0}}><img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"10px",height:"10px", top: 0, position: "absolute", left: "1px"}}/></IconButton>
              </Tooltip>
            </p>
            <CompletionBar complete={subScore3} />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="image">
          <img src={"https://40parables-assets.s3.amazonaws.com/evangelization.svg"} alt="" />
          <p>{handleTranslate("EVANGELIZATION", "evangelization")}</p>
          {getResultReportScore("evangelism")}
        </div>
        <div className="completion-bars-container">
          <div>
            <p>{handleTranslate("Resources Aligned", "resources-aligned")} 
              <Tooltip title={Resources} placement="top" PopperProps={{ style: customStyle, }}> 
                <IconButton style={{padding: 0}}><img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"10px",height:"10px", top: 0, position: "absolute", left: "1px"}}/></IconButton>
              </Tooltip>
            </p>
            <CompletionBar complete={subScore4} />
          </div>
          <div>
            <p>{handleTranslate("Primacy of Programs", "primacy-of-programs")} 
              <Tooltip title={Primacy} placement="top" PopperProps={{ style: customStyle, }}> 
                <IconButton style={{padding: 0}}><img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"10px",height:"10px", top: 0, position: "absolute", left: "1px"}}/></IconButton>
              </Tooltip>
            </p>
            <CompletionBar complete={subScore5} />
          </div>
          <div>
            <p>{handleTranslate("Cultural Reinforcement", "cultural-reinforcement")} 
              <Tooltip title={Cultural} placement="top" PopperProps={{ style: customStyle, }}> 
                <IconButton style={{padding: 0}}><img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"10px",height:"10px", top: 0, position: "absolute", left: "1px"}}/></IconButton>
              </Tooltip>
            </p>
            <CompletionBar complete={subScore6} />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="image">
          <img src={"https://40parables-assets.s3.amazonaws.com/leadership.svg"} alt="" />
          <p>{handleTranslate("LEADERSHIP", "leadership")}</p>
          {getResultReportScore("leadership")}
        </div>
        <div className="completion-bars-container">
          <div>
            <p>{handleTranslate("Vision", "vision")}
              <Tooltip title={Vision} placement="top" PopperProps={{ style: customStyle, }}> 
                <IconButton style={{padding: 0}}><img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"10px",height:"10px", top: 0, position: "absolute", left: "1px"}}/></IconButton>
              </Tooltip>
            </p>
            <CompletionBar complete={subScore7} />
          </div>
          <div>
            <p>{handleTranslate("Planning and Priorities", "planning-and-priorities")}
              <Tooltip title={Planning} placement="top" PopperProps={{ style: customStyle, }}>
                <IconButton style={{padding: 0}}><img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"10px",height:"10px", top: 0, position: "absolute", left: "1px"}}/></IconButton>
              </Tooltip>
            </p>
            <CompletionBar complete={subScore8} />
          </div>
          <div>
            <p>{handleTranslate("Leading out of a team", "leading-out-of-a-team")}
              <Tooltip title={Leading} placement="top" PopperProps={{ style: customStyle, }}>
                <IconButton style={{padding: 0}}><img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"10px",height:"10px", top: 0, position: "absolute", left: "1px"}}/></IconButton>
              </Tooltip>
            </p>
            <CompletionBar complete={subScore9} />
          </div>
          <div>
            <p>{handleTranslate("Growing Leadership Skills", "growing-leadership-skills")}
              <Tooltip title={Growing} placement="top" PopperProps={{ style: customStyle, }}> 
                <IconButton style={{padding: 0}}><img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"10px",height:"10px", top: 0, position: "absolute", left: "1px"}}/></IconButton>
              </Tooltip>
            </p>
            <CompletionBar complete={subScore10} last={true} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Scores;
