export const ProgressMilestonePageDutch = (text, type, section) => {
  switch (section) {
    case "first-pause-screen-heading":
      return " Dank u wel voor het invullen van de vragenlijst tot zover! Ga zo door!";
    case "first-pause-screen-info":
      return "Hoe sterk mijn geloof ook was, de Heilige Geest vervulde me nog meer... Ik realiseerde me dat onze God zo'n overvloedige gever is. Hij doet meer dan dat omdat Hij wil dat je dichter bij Hem komt. Hij achtervolgt je. Hij wil dat je je naar Hem toe keert.";
    case "first-pause-screen-footer-name":
      return "- Shana,"
    case "first-pause-screen-footer-address":
      return "van de parochie van St. Peter en de Winchester Martelaren (Engeland)";
    case "second-pause-screen-heading":
      return " Dank u voor uw tijd! De resultaten zullen de moeite waard zijn :-)";
    case "second-pause-screen-info":
      return "We hebben ontdekt dat we het meest effectief zijn als we een zekere openheid voor de Geest koesteren. Als we een vergadering van anderhalf uur hebben, kunnen we minstens een half uur in gebed doorbrengen, wachtend op de Heilige Geest voordat we aan de slag gaan.";
    case "second-pause-screen-footer-name":
      return "- Bisschop John Adams van Palmerston North,";
    case "second-pause-screen-footer-address":
      return "voormalig parochiepriester van St. Peter Chanel Parish, Nieuw-Zeeland"
    case "third-pause-screen-heading":
      return "Je bent bijna klaar!"
    case "third-pause-screen-info":
      return "We moeten het hen vertellen, met respect voor de cultuur waarin we ons bevinden, die in deze wereld op dit moment seculier is ... Maar we moeten nog steeds ons lied van vreugde en hoop zingen en de Heilige Geest laten doen wat de Heilige Geest met ons wil doen, of we er nu op afgestemd zijn of niet."
    case "third-pause-screen-footer-name":
      return "- Pater Paddy O'Donovan,"
    case "third-pause-screen-footer-address":
      return "Parochie Notre Dame van Mt. Carmel, Verenigde Staten"
    case "continue-cta":
      return "Ga verder"
    case "thank-you":
      return " Dankuwel!"
    case "thank-you-info":
      return "We zijn dankbaar voor uw tijd en voor het delen van uw ideeën over uw parochie."
    case "provide-your-email":
      return " Wanneer u uw e-mailadres achterlaat mailen we u het rapport met de resultaten toe."
    case "email":
      return " E-mailadres:"
    case "email-input-placeholder":
      return "Bijv. s.peters@kerknet.nl"
    case "email-error":
      return "Geef alstublieft een geldig e-mailadres op."
    case "get-my-report-cta":
      return "Stuur mij het rapport"
    case "thank-you-footer-note-1":
      return "*We verzamelen deze informatie alleen van priesters en leiders."
    case "thank-you-footer-note-2":
      return "De antwoorden van parochianen blijven volledig anoniem, daarom hoeft u als parochiaan geen e-mailadres achter te laten."
    case "privacy-policy":
      return "In ons privacybeleid staat hoe we omgaan met uw gegevens.. Vragen hierover kunt u mailen naar bleat@40parables.com."
    case "next-cta":
      return "Stuur mij het rapport";
    case "footer-note":
      return "In ons privacybeleid staat hoe we omgaan met uw gegevens. Vragen hierover kunt u mailen naar support@missionalwaypoint.org."
    default:
      return text;
  }
}