import React from 'react'

function CompletionBar(props) {
  const { border = false, complete = 0, last = false } = props;
  let color = '#97A97C';
  // if (props.complete < 33 && !last) {
  //   color = '#84161D';
  // } else if (props.complete >= 33 && props.complete < 66 && !last ) {
  //   if (props.border) {
  //     color = '#FDBF04';
  //   } else {
  //     color = '#EBBD79';
  //   }
  // } else {
  //   color = '#97A97C';
  // }
  if (complete <= 66) {
    color = '#84161D';
  } else if (complete <= 84) {
    color = '#FDBF04';
  }
  const getStyle = () => {
    return { width: `${complete < 1 ? 1 : complete}%`, backgroundColor: color, borderRight: `${border ? "2px solid #fff" : "0px solid #fff"}` }
  }

  return (
    <div className='completion-bar' style={complete ? { border: `${border ? "2px solid #fff" : "0px solid #fff"}` } : {}} >
      <div className='bar' style={getStyle()} />
    </div>
  )
}

export default CompletionBar

// ---------------------------------------------------------------------------------------------------

// const { border = false, complete = 0, last = false, from } = props;  --- REPLACE LINE 4 WITH THIS
// let progress = props.complete; --- ADD BELOW THE ABOVE LINE

// --- REPLACE 6 - 16 WITH BELOW
// if(from === "compare") { copy any of the following case }
// else  { copy the code from line 6 - 16 }

// --- REPLACE 17 - 19 WITH BELOW
// const getStyle = () => {
//   return { width: `${100}%`, background: color, borderRight : `${ border ? "2px solid #fff" : "0px solid #fff" }` }
// }

// ---------------------------------------------------------------------------------------------------


// CASE 1 :
// if (progress <= 67) {
//   color = `linear-gradient(90deg, #84161D 0%, #84161D 49%, #84161D 50%, #84161D ${progress}%, #E8E8E8 ${progress + 1}%)`; // RED
// } else if (progress >= 67 && progress <= 84 ) {
//   color = `linear-gradient(90deg, #84161D 0%, #84161D 49%, #84161D 50%, #84161D 66%, #FDBF04 67%, #FDBF04 ${progress}%, #E8E8E8 ${progress + 1}%)`; //YELLOW
// } else {
//   color = `linear-gradient(90deg, #84161D 0%, #84161D 49%, #84161D 50%, #84161D 66%, #FDBF04 67%, #FDBF04 83%, #97A97C 84%, #97A97C ${progress}%, #E8E8E8 ${progress + 1}%)`; //GREEN
// }


// CASE 2:
// if (progress <= 50) {
//   color = `linear-gradient(90deg, #84161D 0%, #84161D ${progress}%, #E8E8E8 ${progress + 1}%)`; // RED
// } else if (progress >= 50 && progress <= 67 ) {
//   color = `linear-gradient(90deg, #84161D 0%, #84161D 49%, #EBBD79 50%, #EBBD79 ${progress}%, #E8E8E8 ${progress + 1}%)`; //ORANGE
// } else if (progress >= 67 && progress <= 84 ) {
//   color = `linear-gradient(90deg, #84161D 0%, #84161D 49%, #EBBD79 50%, #EBBD79 66%, #FDBF04 67%, #FDBF04 ${progress}%, #E8E8E8 ${progress + 1}%)`; //YELLOW
// } else {
//   color = `linear-gradient(90deg, #84161D 0%, #84161D 49%, #EBBD79 50%, #EBBD79 66%, #FDBF04 67%, #FDBF04 83%, #97A97C 84%, #97A97C ${progress}%, #E8E8E8 ${progress + 1}%)`; //GREEN
// }


// CASE 3:
// if (progress <= 50) {
//   color = `linear-gradient(90deg, #84161D 0%, #84161D ${progress}%, #E8E8E8 ${progress + 1}%)`; // RED
// } else if (progress >= 50 && progress <= 75 ) {
//   color = `linear-gradient(90deg, #84161D 0%, #84161D 49%, #FDBF04 50%, #FDBF04 ${progress}%, #E8E8E8 ${progress + 1}%)`; //YELLOW
// } else {
//   color = `linear-gradient(90deg, #84161D 0%, #84161D 49%, #FDBF04 50%, #FDBF04 75%, #97A97C 76%, #97A97C ${progress}%, #E8E8E8 ${progress + 1}%)`; //GREEN
// }


// CASE 4:
// if (progress <= 50) {
//   color = `linear-gradient(90deg, #84161D 0%, #84161D ${progress}%, #E8E8E8 ${progress + 1}%)`; // RED
// } else {
//   color = `linear-gradient(90deg, #84161D 0%, #84161D ${progress/3}%, #FDBF04 ${progress/3 + 1}%, #FDBF04 ${progress*2/3}%, #97A97C ${progress*2/3 + 1}%, #97A97C ${progress}%, #E8E8E8 ${progress + 1}%)`; //GREEN
// }