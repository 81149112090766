
import React, { useContext, useEffect, useMemo, useState } from "react";
import * as d3 from "d3";
import { transform } from "framer-motion";
import DashboardContext from "../../Store/DashboardContext";
const PieChart = (props) => {

  let chartData = props.data;
  let dashCtx=useContext(DashboardContext);
  const { showTextHeader } = props;
  // const data = [
  //   { id: 1, label: 'Officer', count: chartData?.officer },
  //   { id: 2, label: 'Helm', count: chartData?.helm },
  //   { id: 3, label: 'Steward', count: chartData?.steward },
  //   { id: 4, label: 'Sailer', count: chartData?.sailer },
  //   { id: 5, label: 'Deckhand', count: chartData?.deckhand },
  //   { id: 6, label: 'passenger', count: chartData?.passenger }
  // ];

  const formatNumber = value => {
    const num = parseFloat(value);
    return Number.isInteger(num) ? num : parseFloat(num.toFixed(1));
  };

  const data = useMemo(() => Object.keys(props.data).map((key, index) => {
    if (props.data[key] !== 0) {
      return { id: index + 1, label: key, count: formatNumber(props.data[key]) };
    }
  }).filter(el => el !== undefined), [props.data]);



  const [activeId, setActiveId] = useState(1)


  let width = props.windowWidth < 600 ? 300 : 500
  let height = props.windowWidth < 600 ? 300 : 500
  let innerRadius = props.windowWidth < 600 ? 40 : 80
  let outerRadius = props.windowWidth < 600 ? 70 : 140

  const createPie = d3
    .pie()
    .value(d => d.count)
    .sort(null);

  //arc for texts
  const createArc2 = d3
    .arc()
    .innerRadius(props.windowWidth < 600 ? 100 : 170)
    .outerRadius(props.windowWidth < 600 ? 150 : 240);

  //color range
  var colorScale = d3.scaleOrdinal()
    .domain(data.map(i => i.label))
    .range(['#44546a', '#87907e', '#bdcda6', '#ebbd79', '#c65b5b', '#84161d']);


  //states
  const [pieData, setPieData] = useState(null)
  const [hoverData, setHoverData] = useState(null)

  useEffect(() => {
    if (data !== null && Array.isArray(data) && data.length > 0) {
      setPieData(createPie(data))
      props.setlabelClicked([data[0].label, data[0].count])
    }
  }, [data]);

  // useEffect(() => {
  //   if (pieData !== null) {
  //     dashCtx.updatePieData(pieData[0])
  //   }
  // }, [pieData]);


  const getArcPath = (d) => {

    const createArc = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .cornerRadius(.7)
    // .padAngle(hoverData && 0.005)

    return <path style={{ transition: '400ms ease-in-out' }} className="arc" d={createArc(d)} fill={colorScale(d.data.label)} ></path>
  }

  const hoverEffect = (d) => {
    if (hoverData && d.data.label !== hoverData?.data.label) {
      return '#ddd'
    } else if (d.data.label == hoverData?.data.label) {
      return colorScale(d.data.label)
    } else {
      return colorScale(d.data.label)
    }
  }

  const hoverEffectText = (d) => {
    if (activeId == d.data.id) {
      return { cursor: 'pointer', fontSize: '17px', fontWeight: '700', textAlign: 'center', width: '100%', transition: '400ms ease-in-out' }
    } else {
      return { cursor: 'pointer', fontSize: '17px', fontWeight: '300', textAlign: 'center', width: '100%', transition: '400ms ease-in-out' }
    }
  }

  const handleIdChange = (id, label, value ) => {
    setActiveId(id)
    props.setlabelClicked([label,value])
  }


  return (
    <svg id="chart-svg" width={width} height={height} style={{ overflow: 'visible', position: 'relative' }}>

      <g transform={`translate(${height / 2} ${width / 2})`}>
        {
          pieData
            ? pieData?.map((d, i) => {

              return <g className="arc" key={i} onMouseEnter={() => {
                dashCtx.updatePieData(d);
                setHoverData(d)
                }} onMouseLeave={() => setHoverData(null)} >
                {getArcPath(d)}
                <text onClick={() => handleIdChange(d.data.id, d.data.label, d.value)} style={hoverEffectText(d)} fill={activeId == d.data.id ? "#84161d" : "#343333"} textAnchor="middle" transform={`translate(${createArc2.centroid(d)[0]}, ${createArc2.centroid(d)[1]} )`} >{showTextHeader(d.data.label)}</text>
                <text onClick={() => handleIdChange(d.data.id, d.data.label, d.value)} style={hoverEffectText(d)} fill={activeId == d.data.id ? "#84161d" : "#343333"} textAnchor="middle" transform={`translate(${createArc2.centroid(d)[0]}, ${createArc2.centroid(d)[1] + 20} )`} >{d.data.count}%</text>
              </g>
            })
            : null
        }

      </g>
    </svg>
  );

};

export default PieChart;