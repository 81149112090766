export const DashboardSidebarDutch = (text, type, section) => {
  switch (section) {
    case "welcome-heading":
      return "Welkom op het dashboard!";
    case "welcome-to":
      return "Welkom op"
    case "your-dashboard":
      return "het dashboard!"
    case "overview":
      return "overzicht";
    case "compare":
      return "vergelijk";
    case "insights":
      return "inzichten";
    case "downloads":
      return "downloads";
    case "resources":
      return "hulpbronnen";
    case "respondents":
      return "Respondenten";
    case "priest":
      return "Priester"
    case "leaders":
      return "Leider"
    case "parishioners":
      return "Parochianen"
    case "refresh-results":
      return "Vernieuw resultaten"
    case "downlaod-results":
      return "Downloaden resultaten"
    case "log-out":
      return "Uitloggen"
    case "download-progress...":
      return "Bezig met downloaden...";
    case "downlaod-failed":
      return "Downloaden mislukt";
    case "downlaod-success":
      return "Downloaden succesvol";
    default:
      return text;
  }
}