export const headerTranslate = (text, type, section) => {
  switch(section) {
    case "weekly-attendance" :
      return "Gem. Weekend Bezoek";
    case "socio-economic": 
    return "Sociaal-Economische Groep";
    case "parish-location":
      return "Parochie Locatie"
    default:
      return text;
  }
}