import React, { useState, useContext, useEffect } from 'react';
import { useParams, useRoutes } from 'react-router-dom';
import Sidebar from '../../../Components/Sidebar/Sidebar';
import { Link } from 'react-router-dom';


import parishPrayImg from '../../../Assets/Images/parish-pray.svg';
import parishCrossImg from '../../../Assets/Images/parish-cross.svg';
import parishIncreaseImg from '../../../Assets/Images/parish-increase.svg';
import Discover from '../../../Components/Discover/Discover';

import Parishpray from '../../../Assets/Images/parish-pray.svg'
import parishCross from '../../../Assets/Images/parish-cross.svg';
import parishGrow from '../../../Assets/Images/parish-increase.svg';
import parishRead from '../../../Assets/Images/parish-read.svg';
import parishInvest from '../../../Assets/Images/parish-invest.svg';
import parishSupport from '../../../Assets/Images/parish-support.svg';
import LanguageContext from '../../../Store/LanguageContext';
import { translate } from '../../../Components/translate/translate';
import DashboardContext from '../../../Store/DashboardContext';





const DashboardResources = () => {

  const { id } = useParams();
  const langCtx = useContext(LanguageContext)
  const dashboardCtx = useContext(DashboardContext);

  const handleTranslate = (text, type) => {

    console.log('langCtx.language: ', langCtx.language)

    switch (langCtx.language) {
      case "Nederlands": {
        return translate(text, "dashboardResourcesDutch", type)
      }
      default: {
        return text;
      }
    }

  }

  useEffect(() => {
    dashboardCtx.setDashboardId(id)
  }, [id])

  const cardsData = [
    { img: "https://40parables-assets.s3.amazonaws.com/parish-increase.svg", label: `${handleTranslate("Guided parish renewal journey", "guided-parish-renewal-journey-heading")}`, para: `${handleTranslate("Parishes around the world are seeing new life through the Divine Renovation Parish Renewal Journey. Discover how your parish can receive support at no cost.", "Parishes-around-description")}`, btn: `${handleTranslate("Learn more", "learn-more-cta")}`, link: "https://divinerenovation.org/parish/?utm_source=Waypoint&utm_medium=website" },
    { img: parishInvest, label: `${handleTranslate("Join an event", "join-an-event-heading")}`, para: `${handleTranslate("Join one of our upcoming events to learn from other parishes, grow in your leadership, and renew your hope.", "join-an-event-description")}`, btn: `${handleTranslate("Register", "Register-cta")}`, link: "https://divinerenovation.org/events/" },
    { img: parishRead, label: `${handleTranslate("Join our email list", "email-heading")}`, para: `${handleTranslate("Leading renewal can be challenging. But you are not alone! Get inspiring stories of hope and the latest events and resources delivered straight to your inbox.", "email-description")}`, btn: `${handleTranslate("Count me in", "count-me-in-cta")}`, link: "https://divinerenovation.org/newsletter/", },
  ]

  const cardsData1 = [
    { img: parishRead, label: `${handleTranslate("Read good news", "read-good-news")}`, para: `${handleTranslate("Leading change can be tough. But you are not alone! Get inspiring stories of hope and renewal delivered to your inbox.", "read-good-news-description")}`, btn: `${handleTranslate("Sign up", "sign-up-cta")}`, link: "https://divinerenovation.org/newsletter/" },
    { img: parishInvest, label: `${handleTranslate("Invest in yourself", "invest-in-yourself")}`, para: `${handleTranslate("Join an event to learn from others, grow in your leadership, and renew your hope.", "invest-in-yourself-description")}`, btn: `${handleTranslate("Register", "register-cta")}`, link: "https://divinerenovation.org/events/" },
    { img: parishSupport, label: `${handleTranslate("Support the mission", "support-the-mission")}`, para: `${handleTranslate("Your parish is taking steps to bring people to Jesus. Imagine thousands more becoming places where lives are transformed. With your help, they can.", "support-the-mission-description")}`, btn: `${handleTranslate("Give", "give-cta")}`, link: "https://divinerenovation.org/give/" },
  ]

  return (
    <div className='resources-dashboard-container'>
      <div className='resources-right-container'>

        <h1>{handleTranslate("Contribute most actively to your parish's renewal journey by best utilizing the Missional Waypoint Tool and resources from Divine Renovation.", "resources-heading-info")}</h1>

        <div className='card-container'>
          <h2>{handleTranslate("Explore the different supports available to your parish.", "explore-different-parish-heading")}</h2>
          {/* <p>Here are three ways to make the most of the Missional Waypoint Tool within your parish</p> */}

          <div className='cards-sections'>
            {cardsData?.map((item, index) => (
              <div className='card' key={index}>
                <div className='img-container'>
                  <img src={item.img} alt='' />
                </div>
                <h3>{item.label}</h3>
                <p>{item.para}</p>
                <Link to={item.link} style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "65px" }} target='_blank'>
                  <button>{item.btn}</button>
                </Link>

              </div>
            ))
            }
          </div>

        </div>
        {/* <div className="card-container">
          <h2>{handleTranslate("Discover the transformation of parishes that are embracing mission", "discover-the-transformation-heading")}</h2>

          <div className='cards-sections'>
            {cardsData1?.map((item, index) => (
              <div className='card' key={index}>
                <div className='img-container'>
                  <img src={item.img} alt='' />
                </div>
                <h3>{item.label}</h3>
                <p>{item.para}</p>
                <Link to={item.link} style={{ display: "flex", alignItems: "center", justifyContent: "center", height:"65px" }} target='_blank'>
                  <button>{item.btn}</button>
                </Link>

              </div>
            ))
            }
          </div>
        </div> */}

        <Discover />


      </div>

    </div>
  )
}

export default DashboardResources;