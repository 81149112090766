import { createContext, useState } from 'react';

const AdditionalQuestionContext = createContext({
  questions: {},
  addQuestions: (addQuestions) => {},
  updateAnswer: (updateAnswer) => {},
  updateTextAnswer: (updateTextAnswer) => {},
});

export function AdditionalQuestionContextProvider(props) {

  const [questions, setQuestions]     = useState([]);
  const [formDetails, setFormDetails] = useState({ weekendAttendance: "", socioEconomic: "", locationType: "" });
  const [form1Details, setForm1Details] = useState({ country: "", diocese: "", parish: "", age: "", city: "" });

  function addQuestionsHandler(data) {
    setQuestions(data);
  }

  function addFormDetailsHandler(data) {
    setFormDetails(data);
  }
  
  function addForm1DetailsHandler(data) {
    setForm1Details(data);
  }

  function updateAnswerHandler(questionId, answer) {
    let question      = questions.find(function(obj) { return obj.id == questionId; });
    question.answer   = answer;
    let localQuestions  = [];
    questions.forEach(q => localQuestions.push(q));
    localStorage.setItem('additionalAnswers', JSON.stringify(localQuestions));
  }

  function updateTextAnswerHandler(questionId, answer) {
    let question        = questions.find(function(obj) { return obj.id == questionId; });
    question.answerTxt  = answer;
    let localQuestions  = [];
    console.log("We are here");
    questions.forEach(q => localQuestions.push(q));
    localStorage.setItem('additionalAnswers', JSON.stringify(localQuestions));
  }

  const context = {
    questions: questions,
    formDetails: formDetails,
    form1Details: form1Details,
    addQuestions: addQuestionsHandler,
    addFormDetails: addFormDetailsHandler,
    addForm1Details: addForm1DetailsHandler,
    updateAnswer: updateAnswerHandler,
    updateTextAnswer: updateTextAnswerHandler
  };

  return <AdditionalQuestionContext.Provider value={context}>
    {props.children}
  </AdditionalQuestionContext.Provider>
}

export default AdditionalQuestionContext;
