import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import CircularProgress from '@mui/material/CircularProgress';
import { TextField, Button, Select as MuiSelect, MenuItem } from "@mui/material";
import Slider from '@mui/material/Slider';
import AdditionalQuestionContext from "../../Store/AdditionalQuestionContext";
import { CheckCircleRounded } from "@mui/icons-material";
import { countryValues } from "../../Assets/countriesList";
import countryNameWithCities from '../../Assets/countryNameWithCities.json';
// import parishValues from '../../Assets/Diocese.json';
// import dioceseValues from '../../Assets/Diocese.json';
import backArrow from '../../Assets/Images/backArrow.svg';
import DesignationContext from "../../Store/DesignationContext";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import LanguageContext from "../../Store/LanguageContext";
import { translate } from '../../Components/translate/translate'
import PreAssessment from "../PreAssessment/PreAssessment";
import { isDisabled } from "@testing-library/user-event/dist/utils";


const FirstForm = () => {

  const langCtx = useContext(LanguageContext)

  const handleTranslate = (text, type) => {

    console.log('langCtx.language: ', langCtx.language)

    switch (langCtx.language) {
      case "Nederlands": {
        return translate(text, "firstFormPageDutch", type)
      }
      default: {
        return text;
      }
    }

  }

  const { id, pageId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const addCtx = useContext(AdditionalQuestionContext);
  const designationCtx = useContext(DesignationContext);

  const [avgWeeklyAttd, setAvgWeeklyAttd] = useState(addCtx?.form1Details?.avgWeeklyAttd);
  const [zipCode, setZipCode] = useState(addCtx?.form1Details?.zipCode);
  const [formQuestions, setFormQuestions] = useState("");
  const [country, setCountry] = useState(addCtx?.form1Details?.country);
  const [city, setCity] = useState(addCtx?.form1Details?.city);
  const [diocese, setDiocese] = useState(addCtx?.form1Details?.diocese);
  const [parish, setParish] = useState(addCtx?.form1Details?.parish);
  const [age, setAge] = useState(addCtx?.form1Details?.age);
  const [countryError, setCountryError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [dioceseError, setDioceseError] = useState(false);
  const [parishError, setParishError] = useState(false);
  const [ageError, setAgeError] = useState(false);
  const [userType, setUserType] = useState(localStorage.getItem("userType"));
  const [locationType, setLocationType] = useState(addCtx?.formDetails?.locationType);
  const [weekendAttendance, setWeekendAttendance] = useState(addCtx?.formDetails?.weekendAttendance);
  const [socioEconomic, setSocioEconomic] = useState(addCtx?.formDetails?.socioEconomic);
  const [locationError, setLocationError] = useState(false);
  const [weekendAttdError, setWeekenAttdError] = useState(false);
  const [socioEconomicError, setSocioEconomicError] = useState(false);
  const [attendanceSliderValue, setAttendanceSliderValue] = useState(100 / 14 * 2);
  const [firstFormData, setFirstFormData] = useState([])
  const [diocesesOptions, setDiocesesOptions] = useState([]);
  const [parishOptions, setParishOptions] = useState([]);
  const [isChecked, setIsChecked] = useState(false)
  const [isSecondChecked, setIsSecondChecked] = useState(false)
  const [cityOptions, setCityOptions] = useState([]);
  const [cityTextChecked, setCityTextChecked] = useState(false);

  const [isParishDisabled, setIsParishDisabled] = useState(false);
  const [iscityDisabled, setIsCityDisabled] = useState(false);
  const [isDioceseDisabled, setIsDioceseDisabled] = useState(false);


  const generateAgeOptions = () => {
    const options = [];
    for (let i = 1; i <= 100; i++) {
      options.push({ age: i });
    }
    return options;
  };

  // const [ageOptions, setAgeOptions] = useState(generateAgeOptions());
  const [ageOptions, setAgeOptions] = useState([
    { age: handleTranslate("25 and below", "age-25-and-below") },
    { age: handleTranslate("26-45", "age-26-45") },
    { age: handleTranslate("46-65", "age-46-65") },
    { age: handleTranslate("66 and above", "age-66-and-above") }
  ]);

  const [isLoading, setIsLoading] = useState(false)
  const [isCreate, setIsCreate] = useState(false)

  const [isPriest, setIsPriest] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const submitBtnRef = useRef(null)

  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isSkip, setIsSkip] = useState(false)

  const weeklyAttendanceOptions = [
    { value: 500, label: 500 },
    { value: 1000, label: 1000 },
    { value: 1500, label: 1500 },
    { value: 2000, label: 2000 },
    { value: 2500, label: 2500 },
  ]

  const economicGroupOptions = [
    { value: "Low (lower 20% of income earners)", label: handleTranslate("Low (lower 20% of income earners)", "lower") },
    { value: "Lower-Middle (bottom half of income earners, but not poor)", label: handleTranslate("Lower-Middle (bottom half of income earners, but not poor)", "lower-middle") },
    { value: "Upper-Middle (top 50% of wage earners in the country but not rich)", label: handleTranslate("Upper-Middle (top 50% of wage earners in the country but not rich)", "upper-middle") },
    { value: "High Income (top 5% of income earners)", label: handleTranslate("High Income (top 5% of income earners)", "upper") },
  ];

  console.log("socioEconomic", socioEconomic)

  useEffect(() => {
    const updateForm1CtxDetails = (fieldName, value) => {
      if (value !== addCtx.form1Details?.[fieldName]) {
        addCtx.addForm1Details(prev => ({ ...prev, [fieldName]: value }));
      }
    };

    updateForm1CtxDetails("country", country);
    updateForm1CtxDetails("diocese", diocese);
    updateForm1CtxDetails("parish", parish);
    updateForm1CtxDetails("age", age);
    updateForm1CtxDetails("city", city);
    // console.log("country", country);
    // console.log("diocese", diocese);
    // console.log("parish", parish);

  }, [country, diocese, parish, age]);

  useEffect(() => {

    const getFormData = () => {
      setIsLoading(true)
      fetch(process.env.REACT_APP_API_URI + '/v4/countrys/' + process.env.REACT_APP_CLIENT_ID, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status === 'success') {
          console.log('data', data)
          setIsLoading(false)
          console.log("addctx", addCtx)
          setFirstFormData(data.data)
        }
      }).catch((err) => console.error("Failed to fetch countries list...", err));
    }

    if (designationCtx.QrCode !== 'parishioner' && designationCtx.QrCode !== 'leader') {
      getFormData();
    }

  }, [])

  useEffect(() => {
    setParish(addCtx?.form1Details?.parish)
    setDiocese(addCtx?.form1Details?.diocese)
    setCountry(addCtx?.form1Details?.country)
    setCity(addCtx?.form1Details?.city)
    setAge(addCtx?.form1Details?.age)
    // console.log('addCtx?.form1Details: ',addCtx?.form1Details)
  }, [addCtx?.form1Details])


  console.log("firstFormData: ", firstFormData)

  useEffect(() => {
    if (locationType !== "" && locationError) {
      removeHintText("locationType")
    }
    const updateFormCtxDetails = (fieldName, value) => {
      if (value !== addCtx.formDetails?.[fieldName]) {
        addCtx.addFormDetails(prev => ({ ...prev, [fieldName]: value }));
      }
    };

    updateFormCtxDetails("weekendAttendance", weekendAttendance);
    updateFormCtxDetails("socioEconomic", socioEconomic);
    updateFormCtxDetails("locationType", locationType);

  }, [weekendAttendance, socioEconomic, locationType]);

  useEffect(() => {
    setAgeOptions([
      { age: handleTranslate("25 and below", "age-25-and-below") },
      { age: handleTranslate("26-45", "age-26-45") },
      { age: handleTranslate("46-65", "age-46-65") },
      { age: handleTranslate("66 and above", "age-66-and-above") }
    ])
  }, [langCtx?.language])

  // useEffect(() => {
  // 	let form1Details = addCtx.form1Details;

  // 	if (form1Details?.avgWeeklyAttd?.length) setAvgWeeklyAttd(form1Details.avgWeeklyAttd);
  // 	if (form1Details?.zipCode?.length) setZipCode(form1Details.zipCode);

  // }, [addCtx.form1Details]);

  useEffect(() => {

    const getFormQuestions = () => {
      fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + pageId, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status == "success") {
          console.log(data);
          setFormQuestions(data?.data.questions)
          // setIsLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      }).catch((err) => console.error("Error while fetching form questions...", err));
    }

    getFormQuestions();

  }, [id]);


  useEffect(() => {
    if (socioEconomic !== "" && attendanceSliderValue !== "" && locationType !== "") {
      setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
    }

  }, [socioEconomic, attendanceSliderValue, locationType])

  const handleSubmitParish = (e) => {
    e?.preventDefault();

    if (country !== "" && diocese !== "" && parish !== "" && age !== "" && (!(isChecked || isSecondChecked) || city !== "")) {

      setIsSaving(true);
      const clientId = process.env.REACT_APP_CLIENT_ID;
      const detailsId = firstFormData.find((el) => (el.parish === parish && el.country === country && el.diocese === diocese));
      const formData = {
        "clientId": clientId,
        "details": {
          "parishId": detailsId?.id,
          "country": country,
          "diocese": diocese,
          "parish": parish,
          "age": age,
          "city": city
        },
      };

      fetch(process.env.REACT_APP_API_URI + `/v4/user/assessment/` + id + `/form/${pageId}`, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status === "success") {
          if (isCreate) {
            fetch(process.env.REACT_APP_API_URI + `/v4/countrys/` + id, {
              method: 'POST',
              mode: 'cors',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                country,
                parish,
                diocese,
                age,
                city
              })
            }).then(response => {
              return response.json();
            }).then(data => {
              if (data.status === "success") {
                console.log(data)

                const parentId = data.id;

                if (parentId) {
                  fetch(process.env.REACT_APP_API_URI + `/v4/user/assessment/` + id + `/updateParent`, {
                    method: 'PUT',
                    mode: 'cors',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ parentId: parentId })
                  }).then(response => {
                    return response.json();
                  }).then(data => {
                    if (data.status === "success") {
                      if (designationCtx.designation === "priest") {
                        setIsPriest(true);
                        setIsSaving(false);
                      } else {
                        navigate("/before-you-start/" + id + "/" + pageId + "?" + searchParams, { replace: true });
                      }
                    }
                  }).catch(error => {
                    console.log(error);
                  });
                }

              }
            }).catch(error => {
              console.log(error);
            });
          }
          else {
            fetch(process.env.REACT_APP_API_URI + `/v4/user/assessment/` + id + `/updateParent`, {
              method: 'PUT',
              mode: 'cors',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ parentId: detailsId?.id })
            }).then(response => {
              return response.json();
            }).then(data => {
              if (data.status === "success") {
                if (designationCtx.designation === "priest") {
                  setIsPriest(true);
                  setIsSaving(false);
                } else {
                  navigate("/before-you-start/" + id + "/" + pageId + "?" + searchParams, { replace: true });
                }
              }
            }).catch(error => {
              console.log(error);
            });
          }
        }
      }).catch(error => {
        console.log(error);
      })

    } else {
      showErrors();
    }
  }

  const handleSubmiQRCodeParish = (parish, diocese, country) => {

    setIsSaving(true);
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const detailsId = firstFormData.find((el) => (el.parish === parish && el.country === country && el.diocese === diocese));
    const formData = {
      "clientId": clientId,
      "details": {
        "parishId": detailsId?.id,
        "country": country,
        "diocese": diocese,
        "parish": parish,
        "age": age,
        "city": city
      },
    };

    fetch(process.env.REACT_APP_API_URI + `/v4/user/assessment/` + id + `/form/${pageId}`, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        if (isCreate) {
          fetch(process.env.REACT_APP_API_URI + `/v4/countrys/` + id, {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              country,
              parish,
              diocese,
              age,
              city
            })
          }).then(response => {
            return response.json();
          }).then(data => {
            if (data.status === "success") {
              console.log(data)

              const parentId = data.id;

              fetch(process.env.REACT_APP_API_URI + `/v4/user/assessment/` + id + `/updateParent`, {
                method: 'PUT',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ parentId: parentId })
              }).then(response => {
                return response.json();
              }).then(data => {
                if (data.status === "success") {
                  if (designationCtx.designation === "priest") {
                    setIsPriest(true);
                    setIsSaving(false);
                  } else {
                    navigate("/before-you-start/" + id + "/" + pageId + "?" + searchParams, { replace: true });
                  }
                }
              }).catch(error => {
                console.log(error);
              });

            }
          }).catch(error => {
            console.log(error);
          });
        }
        else {
          fetch(process.env.REACT_APP_API_URI + `/v4/user/assessment/` + id + `/updateParent`, {
            method: 'PUT',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ parentId: detailsId?.id })
          }).then(response => {
            return response.json();
          }).then(data => {
            if (data.status === "success") {
              if (designationCtx.designation === "priest") {
                setIsPriest(true);
                setIsSaving(false);
              } else {
                navigate("/before-you-start/" + id + "/" + pageId + "?" + searchParams, { replace: true });
              }
            }
          }).catch(error => {
            console.log(error);
          });
        }
      }
    }).catch(error => {
      console.log(error);
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(socioEconomic);
    console.log(weekendAttendance);
    console.log(locationType);
    if (socioEconomic !== "" && attendanceSliderValue !== "" && locationType !== "") {
      setIsSaving(true);
      const clientId = process.env.REACT_APP_CLIENT_ID;
      const formData = {
        "clientId": clientId,
        "details": {
          "locationType": locationType,
          "attendanceSliderValue": attendanceSliderValue,
          "socioEconomic": socioEconomic,
        },
      };

      fetch(process.env.REACT_APP_API_URI + `/v4/user/assessment/` + id + `/form/${pageId}`, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status === "success") {
          setIsSaving(false);
          navigate("/before-you-start/" + id + "/" + pageId + "?" + searchParams, { replace: true });
        }
      }).catch(error => {
        console.log(error);
      })

    } else {
      console.log("There are errors");
      showErrors();
    }
    // if(userType === "priest"){
    // 	if (socioEconomic !== "" && weekendAttendance !== "" && locationType !== "") {
    // 		// submitFirstForm(e)
    // 		navigate("/before-you-start/" + id + "/" + pageId + "?" + searchParams, { replace: true });
    // 	} else {
    // 		showErrors();
    // 		// TODO: remove this navigate route later once API has been updated
    // 		// navigate("/before-you-start/" + id + "/" + pageId + "?" + searchParams, { replace: true });
    // 	}
    // }
    // else{
    // 	if (country !== "" && diocese !== "" && parish !== "") {
    // 		// submitFirstForm(e)
    // 		navigate("/before-you-start/" + id + "/" + pageId + "?" + searchParams, { replace: true });
    // 	} else {
    // 		showErrors();
    // 		// TODO: remove this navigate route later once API has been updated
    // 		// navigate("/before-you-start/" + id + "/" + pageId + "?" + searchParams, { replace: true });
    // 	}
    // }

    // if (userType === "priest") {
    // 	setIsPriest(true)
    // 	// console.log('socioEconomic: ',socioEconomic, "attendanceSliderValue: ",attendanceSliderValue, "locationType: ",locationType)

    // 		showErrors();
    // 		// TODO: remove this navigate route later once API has been updated
    // 		// navigate("/before-you-start/" + id + "/" + pageId + "?" + searchParams, { replace: true });
    // 	// }
    // }
    // else {
    // 	if (country !== "" && diocese !== "" && parish !== "") {
    // 		// submitFirstForm(e)
    // 		navigate("/before-you-start/" + id + "/" + pageId + "?" + searchParams, { replace: true });
    // 	} else {
    // 		showErrors();
    // 		// TODO: remove this navigate route later once API has been updated
    // 		// navigate("/before-you-start/" + id + "/" + pageId + "?" + searchParams, { replace: true });
    // 	}
    // }
  }

  const isNumber = (input) => {
    return !isNaN(input) && typeof input === 'number';
  }

  const showErrors = () => {
    if (userType === "priest") {
      if (weekendAttendance === "") {
        setWeekenAttdError(true);
      }
      if (socioEconomic === "") {
        setSocioEconomicError(true);
      }
      if (locationType === "") {
        setLocationError(true);
      }
    } else {
      if (country === "") {
        setCountryError(true);
      }
      if (city === "") {
        setCityError(true);
      }
      if (diocese === "") {
        setDioceseError(true);
      }
      if (parish === "") {
        setParishError(true);
      }
      if((isChecked || isSecondChecked) && city===''){
        setCityError(true);
      }
      if (age === "") {
        setAgeError(true);
      }
    }
  }

  const showHintText = (field) => {
    if (userType === "priest") {
      if (field == "locationType") {
        if (locationError) {
          return <div className="error-text">Please select your location.</div>
        }
      }
      if (field == "weekendAttendance") {
        if (weekendAttdError) {
          return <div className="error-text">Please select your weekend attendace.</div>
        }
      }
      if (field == "socioEconomic") {
        if (socioEconomicError) {
          return <div className="error-text">Please select your socio-economic group.</div>
        }
      }
    }
    else {
      if (field == "country") {
        if (countryError) {
          return <div className="error-text">Please select your Country.</div>
        }
      }

      if (field == "diocese") {
        if (dioceseError) {
          return <div className="error-text">Please select your Diocese.</div>
        }
      }

      if (field == "parish") {
        if (parishError) {
          return <div className="error-text">Please select your Parish.</div>
        }
      }
      if(field == "city"){
        if (cityError) {
          return <div className="error-text">Please enter your city name.</div>
        }
      }
      if (field == "age") {
        if (ageError) {
          return <div className="error-text">Please enter your valid age.</div>
        }
      }
      if (field == "city") {
        if (cityError) {
          return <div className="error-text">Please enter your city.</div>
        }
      }
    }
  }

  const removeHintText = (field, e) => {
    if (field !== "locationType") {
      if (e.target.closest(".form-detail")) {
        document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = "#4A4A4A")
        // e.target.closest(".form-detail").querySelector(".input-title").style.color = "#ED2028";
      }
    }

    if (field == "country") {
      setCountryError(false);
    }
    if (field == "city") {
      setCityError(false);
    }
    if (field == "diocese") {
      setDioceseError(false);
    }
    if (field == "parish") {
      setParishError(false);
    }
    if (field == "age") {
      setAgeError(false);
    }
    if (field == "city") {
      setCityError(false);
    }
    if (field == "locationType") {
      setLocationError(false);
    }
    if (field == "weekendAttendance") {
      setWeekenAttdError(false);
    }
    if (field == "socioEconomic") {
      setSocioEconomicError(false);
    }
  }

  const prevPage = () => {
    if (isPriest) {
      setIsPriest(false)
      // navigate("/?" + searchParams, { replace: true });
    }
    else {
      navigate("/?" + searchParams, { replace: true });
    }
  }

  const marks = [
    { value: 0, label: "<100" },
    { value: 100 / 14 * 1, label: "100-400" },
    { value: 100 / 14 * 2, label: "400-700" },
    { value: 100 / 14 * 3, label: "700-1000" },
    { value: 100 / 14 * 4, label: "1000-1400" },
    { value: 100 / 14 * 5, label: "1400-1800" },
    { value: 100 / 14 * 6, label: "1800-2200" },
    { value: 100 / 14 * 7, label: "2200-2600" },
    { value: 100 / 14 * 8, label: "2600-3000" },
    { value: 100 / 14 * 9, label: "3000-3400" },
    { value: 100 / 14 * 10, label: "3400-3800" },
    { value: 100 / 14 * 11, label: "3800-4200" },
    { value: 100 / 14 * 12, label: "4200-4600" },
    { value: 100 / 14 * 13, label: "4600-5000" },
    { value: 100 / 14 * 14, label: ">5000" }
  ];

  function valuetext(value) {
    let x = marks.filter(mark => mark.value === value);
    return x[0].label;
  }

  const showButton = () => {
    if (isSaving) {
      return <div className="button-progress"><CircularProgress style={{ color: '#FFFFFF' }} size={23} /></div>
    }
    return handleTranslate("Next", "cta");
  }

  useEffect(() => {
    console.log(Object.keys(designationCtx.priestDetails).length);
    if (designationCtx.priestDetails && Object.keys(designationCtx.priestDetails).length !== 0) {
      const dioceseValue = designationCtx.priestDetails?.find((el) => el.field === "diocese").value;
      const countryValue = designationCtx.priestDetails?.find((el) => el.field === "country").value;
      const parishValue = designationCtx.priestDetails?.find((el) => el.field === "parish").value;
      let city = designationCtx.priestDetails?.find((el) => el.field === "city");
      let cirtValue = "";
      if(city && city.value) {
        cirtValue = city.value;
      }

      // console.log('dioceseValue',dioceseValue)
      // console.log('countryValue',countryValue)
      // console.log('parishValue',parishValue)

      setDiocese(dioceseValue)
      setCountry(countryValue)
      setParish(parishValue);
      if(cirtValue != "") {
        setCity(cirtValue);
      }

      console.log(parishValue)
    }
  }, [designationCtx.priestDetails])

  useEffect(() => {
    // console.log('country',country)
    const dioceseTemp = firstFormData.filter((el) => el.country === country);
    // console.log('dioceseTemp',dioceseTemp)
    // console.log('firstFormData',firstFormData)
    const dioceses = dioceseTemp.map(el => el.diocese);
    const uniqueDioceses = [...new Set(dioceses)];
    setDiocesesOptions(uniqueDioceses);

    const tempUniqueDioceses = uniqueDioceses?.map((el) => {
      return { "value": el, "label": el }
    })
    setDiocesesOptions(tempUniqueDioceses);
    const parishTemp = firstFormData.filter((el) => el.diocese === diocese);
    const parishNames = parishTemp.map((el) => {
      return { "value": el.parish, 'label': el.parish }
    });
    // const sortedParishOptions = parishNames.slice().sort((a, b) => a.value.localeCompare(b.value));
    // const parishTemp = firstFormData.filter((el) => el.diocese === diocese);
    console.log('parishTemp', parishTemp)
    console.log('firstFormData', firstFormData)
    // setParishOptions(parishTemp);


    console.log('parishNames', parishNames)

    setParishOptions(parishNames);

    const uniqueParish = parishTemp?.map((el) => {
      return el.parish;
    })

    console.log("designationCtx.QrCode: ", designationCtx.QrCode)

    if (designationCtx.QrCode === 'parishioner' || designationCtx.QrCode === 'leader') {
      setIsSkip(true)
      if (diocese && country && parish) {
        setIsLoading(true)
        handleSubmiQRCodeParish(parish, diocese, country)
      }
      else {
        getPriestFromHash(id)
      }
    }

    // console.log("uniqueDioceses: ", uniqueDioceses)
    // console.log("uniqueParish: ", uniqueParish)
    if (!uniqueDioceses.includes(diocese) || !uniqueParish.includes(parish)) {
      setIsCreate(true)
    }
    else if (uniqueDioceses.includes(diocese) && uniqueParish.includes(parish)) {
      setIsCreate(false)
    }

  }, [diocese, country, parish, firstFormData, designationCtx.QrCode, designationCtx?.priestDetails]);

  useEffect(() => {
    console.log('diocesesOptions: ', diocesesOptions)
  }, [diocesesOptions])

  useEffect(() => {
    localStorage.getItem("flagPriest") === "true" ? setIsPriest(true) : console.log("aman")
    localStorage.setItem("flagPriest", "false")
  })

  const showLocationDefinition = () => {
    if (locationType === 'Urban') {
      return (
        <p style={{ fontSize: "16px", fontWeight: "300", color: "rgba(3, 3, 3, 0.5)" }}>{handleTranslate("Refer to cities. A lot of people live and work within cities. Cities are densely populated hubs of commerce where a fast-paced lifestyle tends to be the norm.", "urban-info")}</p>
      )
    } else if (locationType === 'Sub-Urban') {
      return (
        <p style={{ fontSize: "16px", fontWeight: "300", color: "rgba(3, 3, 3, 0.5)" }}>{handleTranslate("Locations that build up around the outside of cities. They tend to be less densely populated than the cities they surround.", "sub-urban-info")}</p>
      )
    } else if (locationType === 'Rural') {
      return (
        <p style={{ fontSize: "16px", fontWeight: "300", color: "rgba(3, 3, 3, 0.5)" }}>{handleTranslate("Rural locations are not densely populated. They are outside of cities, further away than suburbs. In rural settings, there are typically large areas of undeveloped land.", "rural-info")}</p>
      )
    } else if (locationType === 'Other') {
      return (
        <p style={{ fontSize: "16px", fontWeight: "300", color: "rgba(3, 3, 3, 0.5)" }}>{handleTranslate("Includes campus or military base chaplaincies, missions, etc.", "other-info")}</p>
      )
    } else {
      return null
    }
  }

  const updateParishes = (e) => {
    console.log(e?.value);
    setDiocese(e?.value)
    console.log(country);
    let parishTemp = firstFormData.filter((el) => el.diocese === e?.value && el.country === country);
    console.log("parish Names: " + parishTemp);
    let parishNames = parishTemp.map((el) => {
      return { "value": el.parish, 'label': el.parish }
    });

    setParishOptions(parishNames);

  }

  const getPriestFromHash = (hash) => {
    setIsLoading(true)
    fetch(process.env.REACT_APP_API_URI + '/v4/user/assessment/' + hash, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        if (data.status == "success") {
          const priestDetailsTemp = data.userDetails;

          console.log("priestDetailsTemp: ", priestDetailsTemp)

          if (priestDetailsTemp && Object.keys(priestDetailsTemp).length !== 0) {
            const dioceseValue = priestDetailsTemp?.find((el) => el.field === "diocese").value;
            const countryValue = priestDetailsTemp?.find((el) => el.field === "country").value;
            const parishValue = priestDetailsTemp?.find((el) => el.field === "parish").value;
            const cityValue = priestDetailsTemp?.find((el) => el.field === "city").value;

            // console.log('dioceseValue',dioceseValue)
            // console.log('countryValue',countryValue)
            // console.log('parishValue',parishValue)

            handleSubmiQRCodeParish(parishValue, dioceseValue, countryValue,)

            setDiocese(dioceseValue)
            setCountry(countryValue)
            setParish(parishValue);
            setCity(cityValue);

            console.log(parishValue)
          }

        }
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Failed to find the priest...", err));
  }

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: 'rgba(52, 51, 51, 0.5)', // Change this to the desired color
      fontWeight: 300
    }),
  };

  const showParish = () => {

    let sortedParishOptions = parishOptions.slice().sort((a, b) => (a.value?.localeCompare(b.value)));

    sortedParishOptions = sortedParishOptions.filter((el) => el.value !== "")

    console.log('sortedParishOptions: ', sortedParishOptions)
    console.log('parishOptions: ', parishOptions)

    // if (designationCtx.designation !== 'parishioner') {
    //   return <div className="form-detail parish">
    //     <p className="input-title">{handleTranslate("Parish", "first-form-parish")}<span className="required-field">*</span></p>
    //     <CreatableSelect classNamePrefix="react-select" className="form-details-text react-select"
    //       value={parish ? { "value": parish, "label": parish } : null}
    //       onFocus={(e) => removeHintText("parish", e)}
    //       onChange={(e) => {
    //         setParish(e?.value)
    //       }}
    //       onCreateOption={(e) => {
    //         setParish(e)
    //       }}

    //       isClearable options={sortedParishOptions}
    //       placeholder={handleTranslate("E.g. Holy Angels", "parish-placeholder")}
    //       styles={customStyles} />
    //     {console.log('parishOptions2', sortedParishOptions)}
    //     {showHintText(handleTranslate("parish", "first-form-parish"))}
    //     <div>
    //     <input type="checkbox" checked={isSecondChecked} onChange={handleSecondCheckboxChange}/>
    //     <label htmlFor="my-checkbox">Check this box if you can't find your parish.</label>
    //     </div>
    //   </div>
    // }
    // else if (designationCtx.designation == 'parishioner' && parishOptions && parishOptions.length <= 1 && parishOptions[0]?.value === '') {
    //   return <div className="form-detail parish">
    //     <p className="input-title">{handleTranslate("parish", "first-form-parish")}<span className="required-field">*</span></p>
    //     <CreatableSelect classNamePrefix="react-select" className="form-details-text react-select"
    //       value={parish ? { "value": parish, "label": parish } : null}
    //       onFocus={(e) => removeHintText("parish", e)}
    //       onChange={(e) => {
    //         setParish(e?.value)
    //       }}
    //       onCreateOption={(e) => {
    //         setParish(e)
    //       }}

    //       isClearable options={sortedParishOptions}
    //       placeholder={handleTranslate("E.g. Holy Angels", "parish-placeholder")}
    //       styles={customStyles} />
    //     {console.log('parishOptions2', sortedParishOptions)}
    //     {showHintText(handleTranslate("parish", "first-form-parish"))}
    //     <div>
    //     <input type="checkbox" checked={isSecondChecked} onChange={handleSecondCheckboxChange}/>
    //     <label htmlFor="my-checkbox" >Check this box if you can't find your parish.</label>
    //     </div>
    //   </div>
    // }
    // else if (designationCtx.designation == 'parishioner') {
    //   return <div className="form-detail parish">
    //     <p className="input-title">{handleTranslate("parish", "first-form-parish")}<span className="required-field">*</span></p>
    //     <CreatableSelect classNamePrefix="react-select" className="form-details-text react-select"
    //       value={parish ? { "value": parish, "label": parish } : null}
    //       onFocus={(e) => removeHintText("parish", e)}
    //       onChange={(e) => {
    //         setParish(e?.value)
    //       }}
    //       onCreateOption={(e) => {
    //         setParish(e)
    //       }}
    //       isClearable options={sortedParishOptions}
    //       placeholder={handleTranslate("E.g. Holy Angels", "parish-placeholder")}
    //       styles={customStyles} />
    //     {console.log('parishOptions2', sortedParishOptions)}
    //     {showHintText(handleTranslate("parish", "first-form-parish"))}
    //   </div>
    // }
    // else {
    // return <div className="form-detail parish">
    //   <p className="input-title">{handleTranslate("parish", "first-form-parish")}<span className="required-field">*</span></p>
    //   <MuiSelect disabled={isParishDisabled} className="form-details-text" value={parish ? parish : ""} onFocus={(e) => removeHintText("parish", e)} onChange={(e) => setParish(e.target.value)} displayEmpty={true} renderValue={parish !== "" ? undefined : () => <div styles={customStyles} className="select-placeholder">{handleTranslate("E.g. Holy Angels", "parish-placeholder")}</div>}>
    //     {sortedParishOptions.map((par, i) => <MenuItem value={par.value}>{par.value}</MenuItem>)}
    //   </MuiSelect>
    //   {console.log('parishOptions2', parishOptions)}
    //   {showHintText("parish")}
    //   <div className="checkbox-container">
    //   <input type="checkbox"  checked={isSecondChecked} onChange={handleSecondCheckboxChange}/>
    //   <label htmlFor="my-checkbox">Check this box if you can't find your parish.</label>
    //   </div>
    //   {isChecked || isSecondChecked ? (
    //     <input type="text" className="checkbox-input" placeholder="Enter Parish Name" onChange={(e) => setParish(e.target.value)} />
    //   ) : null
    // }
    // </div>
    // }

    return <div className="form-detail parish">
      <p className="input-title">{handleTranslate("parish", "first-form-parish")}<span className="required-field">*</span></p>
      {/* <MuiSelect className="form-details-text" value={parish ? parish : ""} onFocus={(e) => removeHintText("parish", e)} onChange={(e) => setParish(e.target.value)} displayEmpty={true} renderValue={parish !== "" ? undefined : () => <div styles={customStyles} className="select-placeholder">{handleTranslate("E.g. Holy Angels", "parish-placeholder")}</div>}>
        {sortedParishOptions.map((par, i) => <MenuItem value={par.value}>{par.value}</MenuItem>)}
      </MuiSelect> */}
      <Select isDisabled={isParishDisabled} classNamePrefix="react-select" className="form-details-text react-select"
        value={parish ? { "value": parish, "label": parish } : null}
        onFocus={(e) => removeHintText("parish", e)}
        onChange={(e) => {
          setParish(e?.value)
        }}
        // onCreateOption={(e) => {
        //   setParish(e)
        // }}
        isClearable options={sortedParishOptions}
        placeholder={handleTranslate("E.g. Holy Angels", "parish-placeholder")}
        styles={customStyles} />
      {console.log('parishOptions2', parishOptions)}
      {showHintText("parish")}
      <div className="checkbox-container">
        <input type="checkbox" checked={isSecondChecked} onChange={handleSecondCheckboxChange} className="custom-checkbox" />
        <label htmlFor="my-checkbox">{handleTranslate("Check this box if you can't find your parish.", "find-parish-checkbox")}</label>
      </div>
      {isChecked || isSecondChecked ? (
        <div className="input-container-parish">
          <input type="text" className="checkbox-input" placeholder="Enter Parish Name" onChange={(e) => setParish(e.target.value)} />
          <div>
            <input type="text" className="checkbox-input" placeholder="Enter City Name" value={city} onChange={(e) => setCity(e.target.value)} onFocus={(e) => removeHintText("city", e)} />
            {showHintText("city")}
          </div>
        </div>
      ) : null
      }
    </div>
  }
  useEffect(()=>{
    setCityOptions(countryNameWithCities[country] || []);
    setCity('');
    console.log('priyanshu7',{city, country, cityOptions})
  },[country])

  const showCity = () => {
    return (
      <div className="form-detail city">
        <p className="input-title">
          {handleTranslate("City", "first-form-city")}
          <span className="required-field">*</span>
        </p>

        <Select isDisabled={iscityDisabled} classNamePrefix="react-select" className="form-details-text react-select"
          value={city ? { value: city, label: city } : null}
          onFocus={(e) => removeHintText("city", e)}
          onChange={(e) => { setCity(e?.value); }}
          isClearable
          options={cityOptions?.map((cityName) => ({ value: cityName, label: cityName, }))}
          placeholder={handleTranslate("E.g. Bazine", "city-placeholder")}
          styles={customStyles}
        />
        {showHintText("city")}

        <div className="checkbox-container">
          <input
            type="checkbox"
            checked={cityTextChecked}
            onChange={handleCityCheckboxChange}
            className="custom-checkbox"
          />
          <label htmlFor="my-checkbox">
            {handleTranslate("Check this box if you can't find your city.", "find-city-checkbox")}
          </label>
        </div>

        {cityTextChecked ? (
          <input
            type="text"
            className="checkbox-input"
            placeholder="Enter City Name"
            onChange={(e) => setCity(e.target.value)}
          />
        ) : null}
      </div>
    );
  };
  
  


  const showDropdowns = () => {
    // if (designationCtx.designation !== 'parishioner') {
    //   const sortedDiocesesOptions = diocesesOptions.slice().sort((a, b) => {
    //     if (a.value < b.value) {
    //       return -1;
    //     }
    //     if (a.value > b.value) {
    //       return 1;
    //     }
    //     return 0;
    //   });
    //   return <>
    //     <CreatableSelect
    //       classNamePrefix='react-select'
    //       className="form-details-text react-select select-placeholder"
    //       value={diocese ? { "value": diocese, "label": diocese } : null}
    //       onFocus={(e) => removeHintText("diocese", e)}
    //       onChange={(e) => updateParishes(e)}
    //       onCreateOption={(e) => setDiocese(e)}
    //       isClearable

    //       options={sortedDiocesesOptions}
    //       placeholder={handleTranslate("E.g. Diocese of Fairbanks", "diocese-placeholder")}
    //       styles={customStyles}
    //     />
    //     {showHintText(handleTranslate("diocese", "first-form-diocese"))}
    //   </>
    // }
    // else if (designationCtx.designation === 'parishioner' && diocesesOptions && diocesesOptions.length === 0) {
    //   const sortedDiocesesOptions = diocesesOptions.slice().sort((a, b) => {
    //     if (a.value < b.value) {
    //       return -1;
    //     }
    //     if (a.value > b.value) {
    //       return 1;
    //     }
    //     return 0;
    //   });
    //   return <>
    //     <CreatableSelect classNamePrefix='react-select' className="form-details-text react-select select-placeholder"
    //       value={diocese ? { "value": diocese, "label": diocese } : null}
    //       onFocus={(e) => removeHintText("diocese", e)}
    //       onChange={(e) => updateParishes(e)}
    //       onCreateOption={(e) => {
    //         setDiocese(e)
    //       }}

    //       isClearable options={sortedDiocesesOptions}
    //       placeholder={handleTranslate("E.g. Diocese of Fairbanks", "diocese-placeholder")} />
    //     {showHintText(handleTranslate("diocese", "first-form-diocese"))}
    //   </>
    // }
    // else {
    console.log("Does this run too");
    const sortedDiocesesOptions = [
      ...diocesesOptions,
    ].slice().sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });
    // return MuiSelect disabled={isDioceseDisabled} className="form-details-text" value={diocese} onFocus={(e) => removeHintText("diocese", e)} onChange={(e) => {
    //   setDiocese(e.target.value);
    //   console.log(firstFormData);
    //   const parishTemp = firstFormData.filter((el) => el.diocese === e.target.value && el.country === country);
    //   const parishNames = parishTemp.map((el) => {
    //     return { "value": el.parish, 'label': el.parish }
    //   });
    //   console.log("parishNames: ", parishNames)
    //   setParishOptions(parishNames);
    // }} displayEmpty={true} renderValue={diocese !== "" ? undefined : () => <div className="select-placeholder">{handleTranslate("E.g. Diocese of Fairbanks", "diocese-placeholder")}</div>}>
    //   {sortedDiocesesOptions.map((dis, i) => <MenuItem value={dis.value}>{dis.value}</MenuItem>)}
    // </MuiSelect>

    // }

    return <Select isDisabled={isDioceseDisabled} classNamePrefix='react-select' className="form-details-text react-select select-placeholder"
      value={diocese ? { "value": diocese, "label": diocese } : null}
      onFocus={(e) => removeHintText("diocese", e)}
      onChange={(e) => {
        setDiocese(e?.value);
        console.log(firstFormData);
        const parishTemp = firstFormData.filter((el) => el.diocese === e?.value && el.country === country);
        const parishNames = parishTemp.map((el) => {
          return { "value": el.parish, 'label': el.parish }
        });
        console.log("parishNames: ", parishNames)
        setParishOptions(parishNames);
      }}
      // onCreateOption={(e) => {
      //   setDiocese(e)
      // }}
      styles={customStyles}
      isClearable options={sortedDiocesesOptions}
      placeholder={handleTranslate("E.g. Diocese of Fairbanks", "diocese-placeholder")} />

  }
  const showAge = () => {
    console.log("age: ", age);
    console.log("addCtx?.form1Details: ", addCtx?.form1Details);
    return (
      <div className="form-detail age">
        <p className="input-title">
          {handleTranslate("Your Age", "first-form-age")}
          <span className="required-field">*</span>
        </p>
        <CreatableSelect
          classNamePrefix='react-select'
          className="form-details-text react-select select-placeholder"
          value={age ? { value: age, label: age } : null}
          onFocus={(e) => removeHintText("age", e)}
          onChange={(selectedOption) => setAge(selectedOption ? selectedOption.value : '')}
          isClearable
          options={ageOptions.map((ageOption) => ({
            value: ageOption.age,
            label: ageOption.age
          }))}
          placeholder={handleTranslate("E.g. Under 25", "age-placeholder")}
          styles={customStyles}
        />
        {showHintText("age")}
      </div>
    );
  };

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);
    setIsSecondChecked(checked)
    setIsDioceseDisabled(checked)
    if (checked) {
      setIsSecondChecked(true);
      setIsParishDisabled(true)
    } else {
      setIsSecondChecked(false);
      setIsParishDisabled(false)

    }
  };

  const handleSecondCheckboxChange = (event) => {
    setIsSecondChecked(event.target.checked);
    setIsParishDisabled(event.target.checked)
  };
  const handleCityCheckboxChange = (event) => {
    setCityTextChecked(event.target.checked);
    setIsCityDisabled(event.target.checked)
  };


  return (
    <>
      {
        !isPriest && <div className="secondform-container">
          {
            isLoading ? <div className="first-form-loading">
              <CircularProgress size={40} />
            </div> : <>
              <div className="secondform">
                <h1>{handleTranslate("Before we begin, tell us a little about your parish", "first-form-heading")}</h1>
              </div>
              <div className="form">
                <div className="form-detail country">
                  <p className="input-title">{handleTranslate("Country", "first-form-country")}<span className="required-field">*</span></p>
                  {/* <TextField className="form-details-text" placeholder="E.g. United Kingdom" value={country} onFocus={(e) => removeHintText("country", e)} onChange={(e) => setCountry(e.target.value)}></TextField> */}
                  <MuiSelect className="form-details-text" value={country} onFocus={(e) => removeHintText("country", e)} onChange={(e) => {
                    setCountry(e.target.value)
                    const dioceseTemp = firstFormData.filter((el) => el.country === e.target.value);
                    const dioceses = dioceseTemp.map(el => el.diocese);
                    const uniqueDioceses = [...new Set(dioceses)];
                    const tempUniqueDioceses = uniqueDioceses?.map((el) => {
                      return { "value": el, "label": el }
                    })
                    setDiocesesOptions(tempUniqueDioceses);
                  }} displayEmpty={true} renderValue={country !== "" ? undefined : () => <div className="select-placeholder">{handleTranslate("E.g. United States of America", "country-placeholder")}</div>}>
                    {countryValues.sort().map((country, i) => <MenuItem value={country}>{country}</MenuItem>)}
                  </MuiSelect>
                  {showHintText("country")}
                </div>
                <div className="form-detail diocese">
                  <p className="input-title">{handleTranslate("Diocese", "first-form-diocese")}<span className="required-field">*</span></p>
                  {showDropdowns()}
                  <div className="checkbox-container">
                    <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} className="custom-checkbox" />
                    <label htmlFor="my-checkbox">{handleTranslate("Check this box if you can't find your diocese.", "find-diocese-checkbox")}</label>
                  </div>
                  {isChecked && (
                    <input type="text" className="checkbox-input" placeholder="Enter Diocese Name" onChange={(e) => setDiocese(e.target.value)} />
                  )}
                  {/* {console.log('parishOptions', parishOptions)} */}
                </div>
                {showParish()}
                {showCity()}
                <div className="form-detail age">
                  {showAge()}
                  {/* {console.log('parishOptions', parishOptions)} */}
                </div>
              </div>
              <div className="cta">
                <img src={backArrow} alt="backarrow" onClick={prevPage} />
                <Button className="cta-btn" ref={submitBtnRef} onClick={handleSubmitParish}>{showButton()}</Button>
              </div>
            </>
          }
        </div>
      }
      {
        isPriest && <div className="firstform-container priest">
          {
            isLoading ? <div className="first-form-loading">
              <CircularProgress size={40} />
            </div> : <>
              <div className="firstform">
                <h1>{handleTranslate("Tell us a little more about your parish", "little-more-about-parish")}</h1>
              </div>
              <div className="main-container">

                <div className="form">
                  <div className="form-detail weekend-attendance">
                    <p className="input-title">{handleTranslate("Avg. Weekend Attendance", "average-weekly-attendance")}<span className="required-field">*</span></p>
                    <div className="slider-container">
                      <div className="side-label-left">{'<100'}</div>
                      <Slider
                        aria-label="Custom marks"
                        defaultValue={100 / 14 * 2}
                        value={attendanceSliderValue}
                        onChange={(e) => setAttendanceSliderValue(e.target.value)}
                        valueLabelFormat={valuetext}
                        step={100 / 14}
                        valueLabelDisplay="on"
                        marks={false}
                      />
                      <div className="side-label-right">{'>5000'}</div>
                    </div>
                    {/* {showHintText("weekendAttendance")} */}
                  </div>
                  <div className="form-detail economic-group">
                    <p className="input-title">{handleTranslate("Socio-Economic Group", "socio-economics-group")}<span className="required-field">*</span></p>
                    <p className="input-desc">{handleTranslate("Describe the socio-economic group of your parish relative to your region.", "describe-the-socio-economic-group")}</p>
                    {/* <TextField className="form-details-text" placeholder="E.g. United Kingdom" value={country} onFocus={(e) => removeHintText("country", e)} onChange={(e) => setCountry(e.target.value)}></TextField> */}
                    <div className="select-container">
                      <MuiSelect className="form-details-text" value={socioEconomic} onChange={(e) => setSocioEconomic(e.target.value)} displayEmpty={true} renderValue={socioEconomic !== "" ? () => economicGroupOptions.find(group => group.value === socioEconomic)?.label : () => <div className="select-placeholder">{handleTranslate("Select/start typing", "select-placeholder")}</div>}>
                        {economicGroupOptions.map((group, i) => (<MenuItem key={i} value={group.value}>{group.label}</MenuItem>))}
                      </MuiSelect>
                    </div>
                    {showHintText("socioEconomic")}
                  </div>
                </div>

                <div className="content-section">
                  <div className="section">
                    <p className="input-title">{handleTranslate("Location", "location")}<span className="required-field">*</span></p>

                    <div className="locationtype">
                      <div className="urban location" style={locationType == "Urban" ? { background: "#1B608A33", border: "2px solid #97A97C" } : {}} onClick={() => { setLocationType("Urban") }}>
                        <div className="complete-icon">{locationType === "Urban" ? <CheckCircleRounded /> : <></>}</div>
                        {handleTranslate("Urban", "urban")}
                      </div>
                      <div className="Sub-Urban location" style={locationType === "Sub-Urban" ? { background: "#1B608A33", border: "2px solid #97A97C" } : {}} onClick={() => { setLocationType("Sub-Urban") }}>
                        <div className="complete-icon">{locationType === "Sub-Urban" ? <CheckCircleRounded /> : <></>}</div>
                        {handleTranslate("Sub-Urban", "sub-urban")}
                      </div>
                      <div className="Rural location" style={locationType === "Rural" ? { background: "#1B608A33", border: "2px solid #97A97C" } : {}} onClick={() => { setLocationType("Rural") }}>
                        <div className="complete-icon">{locationType === "Rural" ? <CheckCircleRounded /> : <></>}</div>
                        {handleTranslate("Rural", "rural")}
                      </div>
                      <div className="Other location" style={locationType === "Other" ? { background: "#1B608A33", border: "2px solid #97A97C" } : {}} onClick={() => { setLocationType("Other") }}>
                        <div className="complete-icon">{locationType === "Other" ? <CheckCircleRounded /> : <></>}</div>
                        {handleTranslate("Other", "other")}
                      </div>
                    </div>
                    {showLocationDefinition()}
                    {showHintText("locationType")}
                    {/* <p>Includes cities — densely populated hubs of commerce where a fast-paced lifestyle tends to be the norm.</p> */}
                    {/* 
                <div className="form form-second">
                  <div className="form-detail Weekend-attendance">
                    <p className="input-title">Weekend attendance<span className="required-field">*</span></p>
                    <TextField className="form-details-text" placeholder="E.g. 500 - 1000" value={weekendAttendance} onFocus={(e) => removeHintText("Weekend-attendance", e)} onChange={(e) => setWeekendAttendance(e.target.value)}></TextField>
                    {showHintText("Weekend-attendance")}
                  </div>
                  <div className="form-detail socio-economic">
                    <p className="input-title">Socio-economic group of your parish<span className="required-field">*</span></p>
                    <TextField className="form-details-text" placeholder="E.g. Lower-middle (bottom half of income earners, but not poor)" value={socioEconomic} onFocus={(e) => removeHintText("socio-economic", e)} onChange={(e) => setSocioEconomic(e.target.value)}></TextField>
                    {showHintText("socio-economic")}
                  </div>
                </div> 
                */}
                  </div>
                </div>
              </div>
              <div className="cta">
                <img src={backArrow} alt="backarrow" onClick={prevPage} />
                <Button className="cta-btn" disabled={isButtonDisabled} onClick={handleSubmit}>{showButton()}</Button>
              </div>
            </>
          }
        </div>
      }

    </>
  )
}

export default FirstForm;
